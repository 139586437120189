import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BrandLogoGreen from '../../assets/images/brand-logo/brand-logo-green.svg';
import DecorTop from '../../assets/images/header/decoration-gray-top1.svg';
import DecorBottom1 from '../../assets/images/header/decoration-gray-bottom-1.svg';
import DecorBottom2 from '../../assets/images/header/decoration-gray-bottom-2.svg';
import LeftArrow from '../../assets/images/arrow-left.svg';

import { HEADER_HEIGHT } from '../../helpers/StyleConstants';

const HeaderWrapper = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT};
  padding-top: 48px;
  padding-right: 48px;
  padding-left: 48px;

  background: #808080;
  position: relative;
`;

const DecorationTop = styled.div`
  position: absolute;
  top: 0;
  left: 25%;

  width: 800px;
  height: 68px;

  background-image: url(${DecorTop});
  background-size: cover;
  background-repeat: no-repeat;
`;

const DecorationBottom = styled.div`
  display: inline-flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const ContentAndDecorWrapper = styled.div`
  width: 710px;
  height: 245px;
  position: relative;
`;

const RightDecoration = styled.div`
  width: 710px;
  height: 245px;

  background-image: url(${DecorBottom2});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 60px;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 25%;
  left: 20%;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  p {
    margin: 0;
    padding: 0;
    color: #ceeb03;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 0;
  width: 670px; // FIX: find better solution
`;

export const CourseHeader = ({ title, onBackClick }) => (
  <HeaderWrapper>
    <DecorationTop />
    <img src={BrandLogoGreen} alt="" />

    <DecorationBottom>
      <ContentAndDecorWrapper>
        <img src={DecorBottom1} alt="" />

        <ContentWrapper>
          <BackButton onClick={onBackClick}>
            <img src={LeftArrow} alt="" />
            <p>Back</p>
          </BackButton>
          <Heading>{title}</Heading>
        </ContentWrapper>
      </ContentAndDecorWrapper>

      <RightDecoration />
    </DecorationBottom>
  </HeaderWrapper>
);

CourseHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired
};
