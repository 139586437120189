import axios from 'axios';

const statsApiAxios = axios.create({
  baseURL: process.env.REACT_APP_STATS_API,
  timeout: 10000
});

export const getSurveyStats = (participantId, surveyId) =>
  statsApiAxios.get('/stats/survey', {
    params: {
      participant_id: participantId,
      survey_id: surveyId
    }
  });

export const getCourseStats = (participantId, courseId) =>
  statsApiAxios.get('/stats/course', {
    params: {
      participant_id: participantId,
      course_id: courseId
    }
  });

export const getParticipantStats = participantId =>
  statsApiAxios.get('/stats/participant', {
    params: {
      participant_id: participantId
    }
  });

export const setSurveyDone = (participantId, surveyId, courseId, scores, email) =>
  statsApiAxios.post('/survey/done', {
    participantId,
    surveyId,
    courseId,
    scores,
    email
  });

export const getWatchedMaterials = (participantId, courseId) =>
  statsApiAxios.get('/materials-watched', {
    params: {
      participant_id: participantId,
      course_id: courseId
    }
  });

export const addWatchedMaterial = (participantId, surveyId, courseId, materialId) =>
  statsApiAxios.post('/material-watched', {
    participantId,
    courseId,
    surveyId,
    materialId,
  });
