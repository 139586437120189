import React from 'react';
import styled from 'styled-components';

import BrandLogoBlack from '../../assets/images/brand-logo/brand-logo-black.svg';

const HeaderWrapper = styled.div`
  width: 100%;
  padding-top: 48px;
  padding-right: 48px;
  padding-left: 48px;

  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 110px;
`;

export const HomeHeader = () => (
  <HeaderWrapper>
    <img src={BrandLogoBlack} alt="" />
  </HeaderWrapper>
);
