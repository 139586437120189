import React from 'react';
import styled from 'styled-components';

import CloseEyeSvg from '../../assets/images/closed-eye.svg';
import OpenEyeSvg from '../../assets/images/open-eye.svg';

const Wrapper = styled.div`
  cursor: pointer;
  width: 49%;
  display: ${props => (props.open ? 'flex' : 'none')};
  background-color: #ffffff;
  justify-content: space-between;

  padding-right: 1em;
`;

const VideoCardDetailsWrapper = styled.div`
  display: flex;
`;

const ImgWrapper = styled.img`
  width: 136px;
  height: 92px;
  width: 204px;
  height: 138px;
`;

const DesctiptionWrapper = styled.div`
  padding-left: 16px;
  margin: auto auto;
`;

const Title = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
`;

const Subtitle = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  color: #808080;
`;

const EyeIcon = styled.div`
  margin: auto 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #350380;
  padding-top: 4px;
`;

const LearningVideosWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    margin-bottom: 1em;
  }
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  color: #000000;
  font-size: 1.1em;
  padding-bottom: 1.1em;
`;

const OpenEye = styled.div`
  margin: auto 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  background-size: cover;
  background-image: url(${OpenEyeSvg});
`;

const LearningVideoCard = ({ video, onVideoClick, open, isYoutubeVideo, watched }) => {
  return (
    <Wrapper open={open} onClick={() => onVideoClick(video, isYoutubeVideo)}>
      <VideoCardDetailsWrapper>
        <ImgWrapper src={video.thumbnail.url} />
        <DesctiptionWrapper>
          <Title>{video.title}</Title>
          <Subtitle>• Mental Awarness • {video.duration}</Subtitle>
        </DesctiptionWrapper>
      </VideoCardDetailsWrapper>
     { watched 
     ? <OpenEye />
     : <EyeIcon>
          <img alt="Not seen" src={CloseEyeSvg} />{' '}
        </EyeIcon>
    }
    </Wrapper>
  );
};

const LearningVideos = ({ videos, isYoutubeVideo = false, surveyScore, onVideoClick }) => {
  return videos.map((video, i) => (
    <LearningVideoCard
      video={video}
      key={i}
      isYoutubeVideo={isYoutubeVideo}
      surveyScore={surveyScore}
      onVideoClick={onVideoClick}
      watched={video.watched}
      open={video.minimalScore <= surveyScore}
    />
  ));
};

export const LearningVideosSection = ({ survey, surveyScore, onVideoClick }) => {
  if (!survey.youtubeVideos.length && !survey.videos.length) return null;

  return (
    <>
      <Heading>Videos</Heading>
      <LearningVideosWrapper>
        <LearningVideos
          videos={survey.youtubeVideos}
          onVideoClick={onVideoClick}
          surveyScore={surveyScore}
          isYoutubeVideo
        />
        <LearningVideos
          videos={survey.videos}
          onVideoClick={onVideoClick}
          surveyScore={surveyScore}
        />
      </LearningVideosWrapper>
    </>
  );
};
