import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import xing from '../../assets/images/social-icons/xing.svg';
import fb from '../../assets/images/social-icons/fb.svg';
import linkedin from '../../assets/images/social-icons/linkedin.svg';

const ICON_IMAGE_LIST = {
  xing,
  fb,
  linkedin
};

const Icon = styled.a`
  height: 32px;
  width: 32px;
  background-image: url(${$props => ICON_IMAGE_LIST[$props.image]});
`;

export const SocialIcon = ({ type, link }) => <Icon href={link} image={type} />;

SocialIcon.propTypes = {
  link: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
