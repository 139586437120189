import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #fdd;
  border-color: #000 transparent #000 transparent;
  margin: auto auto;
  animation: ${rotate} 2s infinite;
`;

const LoadingPageWrapper = styled.div`
  display: flex;
  margin: 0 0;
  padding: 0 0;
  height: 100vh;
  width: 100vw;
`;

export const Loading = () => (
  <LoadingPageWrapper>
    <Spinner />
  </LoadingPageWrapper>
);
