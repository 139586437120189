import React, { useState } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { surveyStatus } from '../helpers/enums/surveyStatus';

import { Loading } from '../components/Loading';
import { RadarChartComponent } from '../components/Profile/RadarChart';
import { CourseHeader } from '../components/Course/CourseHeader';
import { LearningMaterialsModal } from '../components/Course/LearningMaterialModal';
import { Heading1, Heading2, Heading3, Heading4, Text } from '../components/Shared/Typography';

import profile from '../helpers/profile/profileStore';
import { useCourseWithStats } from '../hooks/courseWithStats';

const LearningMaterialsCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
  & > div:last-child {
    margin-right: 0px;
  }
`;

const Wrapper = styled.div`
  box-shadow: 0px -4px 0px 0px #ceeb03;

  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 150px;
  width: 31%;

  cursor: pointer;
  padding-bottom: 20px;
`;

const DesctiptionWrapper = styled.div`
  padding: 16px 16px;
  margin: 0 0;
  justify-content: flex-start;
  overflow: hidden;
`;

const Page = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CongratulationsContainer = styled.div`
  padding-top: 50px;
  width: 80vw;
  margin: auto auto;
  display: flex;
  flex-direction: column;
`;

const Congratulations = props => {
  const { courseId, participantId } = props.match.params;

  const { loading, error, data } = useCourseWithStats(courseId, participantId);
  const [isToolBoxOpen, setIsToolBoxOpen] = useState(false);
  const [toolboxMaterial, setToolboxMaterial] = useState({});

  if (loading) return <Loading />;
  if (error) return <p>error</p>;

  const { course } = data;

  const hasCourseCompleted = course.surveys.every(
    survey => survey.status === surveyStatus.RE_EVALUATED
  );

  if (!hasCourseCompleted) return <Redirect to="/" />;

  const radarCharData = profile.courseChartData(course.surveys);

  const onOpenToolBoxClick = () => {
    setToolboxMaterial({ article: course.toolbox });
    setIsToolBoxOpen(true);
  };

  const beforeToolBoxModalClose = clickedElement => {
    if (clickedElement && !clickedElement.classList.contains('modal-background')) return;
    setIsToolBoxOpen(false);
  };

  return (
    <>
      <LearningMaterialsModal
        open={isToolBoxOpen}
        onCloseClick={beforeToolBoxModalClose}
        material={toolboxMaterial}
      />
      <Page>
        <CourseHeader title={course.topic} onBackClick={() => props.history.push('/')} />

        <CongratulationsContainer>
          <Heading1>
            Congratulations on finishing
            <br />
            {course.topic} course!
          </Heading1>
          <br />
          <Heading2>You’ve made a cool progress!</Heading2>

          <RadarChartComponent data={radarCharData} />
          <Heading4>In the beginning you’ve scored:</Heading4>
          <LearningMaterialsCardsWrapper>
            {course.surveys.map(survey => (
              <Wrapper>
                <DesctiptionWrapper>
                  <Text>{survey.topic}</Text>
                  <div style={{ display: 'flex' }}>
                    <Heading3>
                      {Math.round(profile.scoresArithmeticMean(survey.firstScore))}
                    </Heading3>
                    <Text style={{ paddingTop: 15, paddingLeft: 5 }}>points</Text>
                  </div>
                </DesctiptionWrapper>
              </Wrapper>
            ))}
          </LearningMaterialsCardsWrapper>

          <Heading3>Your current results are:</Heading3>
          <LearningMaterialsCardsWrapper>
            {course.surveys.map(survey => (
              <Wrapper>
                <DesctiptionWrapper>
                  <Text>{survey.topic}</Text>
                  <div style={{ display: 'flex' }}>
                    <Heading3>
                      {Math.round(profile.scoresArithmeticMean(survey.lastScore))}
                    </Heading3>
                    <Text style={{ paddingTop: 15, paddingLeft: 5 }}>points</Text>
                  </div>
                </DesctiptionWrapper>
              </Wrapper>
            ))}
          </LearningMaterialsCardsWrapper>
          {course?.toolbox && (
            <Link onClick={onOpenToolBoxClick} style={{ textDecoration: 'none', marginBottom: 50 }}>
              <Heading3> get my toolbox</Heading3>
            </Link>
          )}
        </CongratulationsContainer>
      </Page>
    </>
  );
};

export const CongratulationsPage = withRouter(Congratulations);
