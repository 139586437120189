import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { COURSE_CARD_BG_COLORS } from '../../helpers/StyleConstants';

import LockIcon from '../../assets/images/course-cards/lock-icon.svg';
import FooterIconClosed from '../../assets/images/course-cards/footer-icon-closed.svg';
import OpenEyeSvg from '../../assets/images/open-eye.svg';

import focus from '../../assets/images/course-cards/card-focus.svg';
import stress from '../../assets/images/course-cards/card-stress.svg';
import selfAwareness from '../../assets/images/course-cards/card-self-awareness.svg';

const IMAGE_LIST = {
  focus,
  stress,
  selfAwareness
};

const Card = styled.li`
  min-height: 244px;
  display: inline-flex;
  width: 100%;
  background: ${$props => COURSE_CARD_BG_COLORS[$props.type]};
  cursor: pointer;
  margin-bottom: 32px;

  opacity: ${$props => ($props.active ? '1' : '0.6')};
  pointer-events: ${$props => ($props.active ? 'auto' : 'none')};
`;

const PictureBlock = styled.div`
  width: 360px;
  height: 244px;
  background-image: url(${({ image }) => image ? image.url : IMAGE_LIST['focus']});
  background-size: cover;
  background-repeat: none;
`;

const ContentBlock = styled.div`
  background: #fff;
  padding: 48px 30px 28px;
  width: 75%; // TODO: probably needs mobile version
  max-width: 830px;
  position: relative;
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 1.4em;
  line-height: 100%;
  letter-spacing: -0.02em;
  padding: 0;
  margin: 0 0 12px;
  width: 100%;
`;

const Description = styled.p`
  max-width: 640px;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 24px; // 32px is way too much!
  color: #808080;
  margin-bottom: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
`;

const Footer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const FooterIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  white-space: nowrap;
`;

const FooterIcon = styled.div`
  height: 24px;
  width: 24px;
  background-image: ${props => (props.open ? `url(${OpenEyeSvg})` : `url(${FooterIconClosed})`)};
  margin-right: 5px;
`;

const FooterIconText = styled.p`
  margin: 0;
  padding: 0;
  height: 24px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Lock = styled.div`
  width: 21px;
  height: 24px;
  background-image: url(${LockIcon});

  position: absolute;
  top: 24px;
  right: 24px;
`;

export const CourseCard = ({ topic, active, description, type, courseId, surveys, image }) => (
  <Link style={{ color: 'inherit', textDecoration: 'none' }} to={`/course/${courseId}`}>
    <Card active={active} type={type} className="course-card">
      <PictureBlock image={image} type={type} />

      <ContentBlock>
        {!active && <Lock />}
        <Heading>{topic}</Heading>
        <Description>{description}</Description>

        <Footer>
          {surveys.slice(0, 6).map((survey, i) => (
            <FooterIconWrapper key={i}>
              <FooterIcon open={survey.status !== null} />
              <FooterIconText>{survey.topic}</FooterIconText>
            </FooterIconWrapper>
          ))}
        </Footer>
      </ContentBlock>
    </Card>
  </Link>
);

CourseCard.propTypes = {
  active: PropTypes.bool.isRequired,
  topic: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      topic: PropTypes.string.isRequired
    }).isRequired
  ),
  image: PropTypes.object
};

CourseCard.default = {
  type: 'focus'
};
