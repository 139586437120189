import { gql } from 'apollo-boost';

export const GET_COURSES = gql`
  query {
    courses {
      topic
      description
      id
      order
      active
      image {
        url
      }
      surveys {
        id
        topic
      }
    }
  }
`;

export const GET_COURSE_SURVEYS = gql`
  query($id: ID!) {
    course(id: $id) {
      topic
      active
      toolbox
      description
      image {
        url
      }
      surveys {
        id
        summary
        topicStartVideoUrl
        topicFinishVideoUrl
        description
        topic
        questionnaire {
          questionText
          id
        }
        youtubeVideos {
          id
          title
          duration
          url
          minimalScore
          thumbnail {
            url
          }
        }
        videos {
          id
          title
          duration
          videoUrl 
          minimalScore
          thumbnail {
            url
          }
        }
        learningMaterials {
          id
          topic
          article
          description
          thumbnail {
            url
          }
        }
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query($id: ID!) {
    profile(id: $id) {
      email
      avatar {
        url
      }
      name
      id
    }
  }
`;

export const GET_LANDING_FOOTERS = gql`
  query {
    landings {
      footer {
        linkedinLink
        xingLink
        fbLink

        contactEmail
        contactAddress
        contactPhoneNumber
      }
    }
  }
`;

export const GET_E_LEARNING_DATA = gql`
  query {
    eLearningData {
      description
      title
      videoUrl
    }
  }
`;
