import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';
import { GET_PROFILE } from '../helpers/graphqlQueries';
import profile from '../helpers/profile/profileStore';
import { Loading } from '../components/Loading';

const AuthComponent = props => {
  const { error, loading, data } = useQuery(GET_PROFILE, {
    variables: { id: props.match.params.id }
  });
  if (loading) return <Loading />;
  if (error) return <p>Wrong id :(</p>;

  profile.auth(data.profile);
  return profile.isProfileLoggedIn() ? <Redirect to="/">OK</Redirect> : <p>Something wrong</p>;
};

export const AuthPage = withRouter(AuthComponent);
