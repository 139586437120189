import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

const LightOffAnimation = keyframes`
    from {
        background-color: rgba(0, 0, 0, 0%);
    }
    to {
        background-color: rgba(0, 0, 0, 60%);
    }
`;

const FadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, -100vh);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0vh);

  }
`;

const ModalWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 60%);
  animation: ${LightOffAnimation} 0.7s 1;
`;

const ModalOpen = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

const YouTubeVideo = styled.iframe`
  width: 1008px;
  height: 567px;
  margin: auto auto;
  border: none;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.8);
  animation: ${FadeInAnimation} 0.7s 1;
`;

const Video = styled.video`
  width: 1008px;
  height: 567px;
  margin: auto auto;
  border: none;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.8);
  animation: ${FadeInAnimation} 0.7s 1;
`;

const youtebeIdRegex = /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;
const getYoutubeId = url => url.match(youtebeIdRegex)[1];

const VideoComponent = ({ videoMaterials, isYoutubeVideo }) => {
  if (videoMaterials !== undefined && isYoutubeVideo) {
    const youtubeId = getYoutubeId(videoMaterials.url);

    return (
      <YouTubeVideo
        className="video"
        src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  } else {
    return (
      <Video className="video" autoPlay controls>
        <source
          type="video/mp4"
          src={videoMaterials.videoUrl}
        />
      </Video>
    );
  }
};

export const VideoModal = ({ onCloseClick, open, videoMaterials, isYoutubeVideo }) => {
  const onEscPress = useCallback(
    event => {
      event.preventDefault();

      if (event.key === 'Escape') {
        onCloseClick(null, videoMaterials);
      }
    },
    [onCloseClick, videoMaterials]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('keyup', onEscPress);
    } else {
      document.removeEventListener('keyup', onEscPress);
    }
  }, [open, onEscPress]);

  return (
    open && (
      <ModalWrapper className="modal-background" onClick={event => onCloseClick(event.target, videoMaterials)}>
        <ModalOpen />
        <VideoComponent videoMaterials={videoMaterials} isYoutubeVideo={isYoutubeVideo} />
      </ModalWrapper>
    )
  );
};

VideoModal.props = {
  onCloseClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isYoutubeVideo: PropTypes.bool.isRequired,
  videoMaterials: PropTypes.object.isRequired
};

VideoModal.defaultProps = {
  isYoutubeVideo: false
};
