import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import Markdown from 'react-markdown';

const LightOffAnimation = keyframes`
    from {
        background-color: rgba(0, 0, 0, 0%);
    }
    to {
        background-color: rgba(0, 0, 0, 60%);
    }
`;

const FadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, -100vh);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0vh);

  }
`;

const ModalWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 60%);
  overflow-y: initial !important;
  animation: ${LightOffAnimation} 0.7s 1;
`;

const ModalOpen = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

const MaterialWrapper = styled.div`
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.8);
  margin: 30px auto;
  background: white;
  overflow-y: auto;
  padding: 50px 40px;
  min-height: 40vh;
  width: 595px;
  animation: ${FadeInAnimation} 0.7s 1;

  & > p {
    margin: 1em 0;
  }

  & > img {
    max-width: 100%;
  }

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    font-weight: normal;
    color: #111;
    line-height: 1em;
    margin-bottom: 1em;
  }

  & > h4,
  & > h5,
  & > h6 {
    font-weight: bold;
  }

  & > h1 {
    font-size: 2.5em;
  }

  & > h2 {
    font-size: 2em;
  }

  & > h3 {
    font-size: 1.5em;
  }

  & > h4 {
    font-size: 1.2em;
  }

  & > h5 {
    font-size: 1em;
  }

  & > h6 {
    font-size: 0.9em;
  }

  & > blockquote {
    color: #666666;
    margin: 0;
    padding-left: 3em;
    border-left: 0.5em #eee solid;
  }

  & > hr {
    display: block;
    height: 2px;
    border: 0;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #eee;
    margin: 1em 0;
    padding: 0;
  }

  & > pre,
  & > code,
  & > kbd,
  & > samp {
    color: #000;
    font-size: 0.98em;
  }

  & > pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 12px;
  }

  & > b,
  strong {
    font-weight: bold;
  }

  & > dfn {
    font-style: italic;
  }

  & > ins {
    background: #ff9;
    color: #000;
    text-decoration: none;
  }

  & > mark {
    background: #ff0;
    color: #000;
    font-style: italic;
    font-weight: bold;
  }

  & > sub,
  & > sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  & > sup {
    top: -0.5em;
  }

  & > sub {
    bottom: -0.25em;
  }

  & > ul,
  & > ol {
    margin: 1em 0;
    padding: 0 0 0 2em;
  }

  & > li p:last-child {
    margin: 0;
  }

  & > dd {
    margin: 0 0 0 2em;
  }

  & > img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
  }

  & > table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  & > td {
    vertical-align: top;
  }

  & > a {
    color: #0645ad;
    text-decoration: none;
  }

  & > a:visited {
    color: #0b0080;
  }

  & > a:hover {
    color: #06e;
  }

  & > a:active {
    color: #faa700;
  }

  & > a:focus {
    outline: thin dotted;
  }

  & > a:hover,
  a:active {
    outline: 0;
  }

  & > ::-moz-selection {
    background: rgba(255, 255, 0, 0.3);
    color: #000;
  }

  & > ::selection {
    background: rgba(255, 255, 0, 0.3);
    color: #000;
  }

  & > a::-moz-selection {
    background: rgba(255, 255, 0, 0.3);
    color: #0645ad;
  }

  & > a::selection {
    background: rgba(255, 255, 0, 0.3);
    color: #0645ad;
  }
`;

export const LearningMaterialsModal = ({ onCloseClick, open, material }) => {
  const onEscPress = useCallback(
    event => {
      event.preventDefault();

      if (event.key === 'Escape') {
        onCloseClick(null, material);
      }
    },
    [onCloseClick, material]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('keyup', onEscPress);
    } else {
      document.removeEventListener('keyup', onEscPress);
    }
  }, [open, onEscPress]);

  return (
    open && (
      <ModalWrapper
        className="modal-background"
        onClick={event => onCloseClick(event.target, material)}
      >
        <ModalOpen />
        <MaterialWrapper>
          <Markdown source={material.article} />
        </MaterialWrapper>
      </ModalWrapper>
    )
  );
};

LearningMaterialsModal.props = {
  onCloseClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

LearningMaterialsModal.defaultProps = {
  isYoutubeVideo: false
};
