import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_COURSE_SURVEYS, GET_COURSES } from '../helpers/graphqlQueries';
import profile from '../helpers/profile/profileStore';
import {
  getCourseStats,
  getParticipantStats,
  getWatchedMaterials
} from '../helpers/profile/statsService';

export const useCourseWithStats = (courseId, profileId) => {
  const profileData = profile.get();

  const courseSurveysQuery = useQuery(GET_COURSE_SURVEYS, {
    variables: { id: courseId }
  });

  const [updateCount, setUpdateCount] = useState(0);
  const [courseStats, setCourseStats] = useState(null);
  const [courseWatchedMaterials, setWatchedMaterials] = useState(null);

  useEffect(() => {
    const fecthData = async () => {
      const courseStatsResponse = await getCourseStats(profileId || profileData.id, courseId);
      setCourseStats(courseStatsResponse.data);
    };

    fecthData();
  }, [courseId, profileId, profileData.id, updateCount]);

  useEffect(() => {
    const fecthData = async () => {
      const watchedMaterialsResponse = await getWatchedMaterials(
        profileId || profileData.id,
        courseId
      );
      setWatchedMaterials(watchedMaterialsResponse.data);
    };

    fecthData();
  }, [courseId, profileId, profileData.id, updateCount]);

  if (courseSurveysQuery.loading) return { ...courseSurveysQuery };
  if (courseSurveysQuery.error) return { ...courseSurveysQuery };
  if (!Array.isArray(courseStats)) return { ...courseSurveysQuery, loading: true };
  if (!Array.isArray(courseWatchedMaterials)) return { ...courseSurveysQuery, loading: true };

  const { course } = courseSurveysQuery.data;

  const surveysDataWithStats = course.surveys.map(survey => {
    const surveyStats = courseStats.find(surveyStatistic => survey.id === surveyStatistic.surveyId);

    const watchedMaterials = courseWatchedMaterials.filter(
      watchedMaterial => survey.id === watchedMaterial.surveyId
    );

    const watchedVideos = survey.videos.map(video => {
      return {
        watched: !!courseWatchedMaterials.find(material => material.materialId === video.id),
        ...video
      };
    });

    const watchedYoutubeVideos = survey.youtubeVideos.map(youtubeVideo => {
      return {
        watched: !!courseWatchedMaterials.find(material => material.materialId === youtubeVideo.id),
        ...youtubeVideo
      };
    });

    const watchedLearningMaterials = survey.learningMaterials.map(learningMaterial => {
      return {
        watched: !!courseWatchedMaterials.find(
          material => material.materialId === learningMaterial.id
        ),
        ...learningMaterial
      };
    });

    return {
      ...survey,
      youtubeVideos: watchedYoutubeVideos,
      videos: watchedVideos,
      learningMaterials: watchedLearningMaterials,
      status: surveyStats !== undefined ? surveyStats.status : null,
      firstScore: surveyStats !== undefined ? surveyStats.firstScore : null,
      lastScore: surveyStats !== undefined ? surveyStats.lastScore : null,
      watchedMaterials
    };
  });


  return {
    ...courseSurveysQuery,
    update: () => {
      courseSurveysQuery.refetch();
      setUpdateCount(updateCount + 1);
    },
    data: { course: { ...course, surveys: surveysDataWithStats } }
  };
};

export const useCoursesWithStats = () => {
  const coursesQuery = useQuery(GET_COURSES);

  const [participantStats, setParticipantStats] = useState([]);

  useEffect(() => {
    const profileData = profile.get();

    const fecthData = async () => {
      const participantStatsResponse = await getParticipantStats(profileData.id);
      setParticipantStats(participantStatsResponse.data);
    };

    fecthData();
  }, []);

  if (coursesQuery.loading) return { ...coursesQuery };
  if (coursesQuery.error) return { ...coursesQuery };

  const { courses } = coursesQuery.data;

  const coursesWithStats = courses.map(course => {
    const surveysDataWithStats = course.surveys.map(survey => {
      const surveyStats = participantStats.find(
        surveyStatistic => survey.id === surveyStatistic.surveyId
      );

      return {
        ...survey,
        status: surveyStats !== undefined ? surveyStats.status : null,
        firstScore: surveyStats !== undefined ? surveyStats.firstScore : null,
        lastScore: surveyStats !== undefined ? surveyStats.lastScore : null
      };
    });

    return { ...course, surveys: surveysDataWithStats };
  });

  return { ...coursesQuery, data: { courses: coursesWithStats } };
};
