import { Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';

import { AuthPage } from '../pages/auth';
import { SurveyPage } from '../pages/survey';
import { CoursePage } from '../pages/course';
import { HomePage } from '../pages/home';
import { CongratulationsPage } from '../pages/congratulations';
import { SummaryPage } from '../pages/summary';

import profile from '../helpers/profile/profileStore';

const PrivateRoute = props => {
  !profile.isProfileLoggedIn() && window.location.replace(process.env.REACT_APP_NOT_AUTH_REDIRECT);

  // Dirty fix always on the top on the next page.  
  window.scrollTo(0, 0);
  
  return <Route {...props}/>;
};

export const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/" component={HomePage} />
    <PrivateRoute exact path="/course/:id" component={CoursePage} />
    <PrivateRoute exact path="/course/:courseId/survey/:surveyId" component={SurveyPage} />
    <PrivateRoute exact path="/congratulations/:courseId/:participantId" component={CongratulationsPage} />
    <Route exact path="/summary/:courseId/:surveyId/:participantId" component={SummaryPage} />
    <Route exact path="/auth/:id" component={AuthPage} />
    <Redirect from="*" to="/" />
  </Switch>
);
