import styled from 'styled-components';

import { ReactComponent as LeftArrowSvg } from './assets/images/arrow-thin-left.svg';
import { ReactComponent as RightArrowSvg } from './assets/images/arrow-thin-right.svg';

const itemsMargin = 24;

export const CarouselContent = styled.div`
  display: flex;
  overflow: visible;

  & > * {
    transform: translateX(${({ page, itemWidth }) => page * -(itemWidth + itemsMargin)}px);

    height: ${({ itemHeight }) => itemHeight}px;
    min-width: ${({ itemWidth }) => itemWidth}px;
    max-width: ${({ itemWidth }) => itemWidth}px;
    margin-right: ${itemsMargin}px;

    transition: transform 0.8s ease;

  }

  & > div:last-child {
    margin-right: 0px;
  }
`;

export const Heading = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  color: #000000;
  font-size: 1.1em;
  padding-bottom: 1.1em;;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between; 
`;

export const NavButtonsWrapper = styled.div`
  margin: auto 0;
  img {
    padding-right: 10px;
  }
`;

export const NextPage = styled(RightArrowSvg)`
  cursor: ${props => (props.enabled ? 'pointer' : 'default')};
  path {
    fill: ${props => (props.enabled ? 'black' : 'grey')};
  }
  margin: 0 10px;
`;

export const PrevPage = styled(LeftArrowSvg)`
  cursor: ${props => (props.enabled ? 'pointer' : 'default')};
  path {
    fill: ${props => (props.enabled ? 'black' : 'grey')};
  }
`;