import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';

const STAR_COLOR_EMPTY = '#C4C4C4';
const STAR_COLOR = '#BEE900';

const StarWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 32px;
  margin-bottom: 16px;
`;

const Star = styled.i`
  font-style: normal;
  font-size: 15px;
  margin-right: 8px;
`;

const Label = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
`;

export const StarRating = ({ label, starNum, value, editing }) => (
  <StarWrapper>
    <Label>{label}</Label>
    <StarRatingComponent
      name="rate2"
      editing={editing}
      starCount={starNum}
      value={value}
      starColor={STAR_COLOR}
      emptyStarColor={STAR_COLOR_EMPTY}
      renderStarIcon={(index, value) => {
        return <Star>★</Star>;
      }}
    />
  </StarWrapper>
);

StarRating.propTypes = {
  starNum: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  editing: PropTypes.bool.isRequired,
  label: PropTypes.string
};
