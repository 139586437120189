import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ProfileRightBlock } from '../components/Profile';
import { SurveyHeader } from '../components/Survey/SurveyHeader';
import Questionnaire from '../containers/SurveyQuestionnaire';
import { Loading } from '../components/Loading';

import profile from '../helpers/profile/profileStore';
import { getSurveyStats } from '../helpers/profile/statsService';
import { useCourseWithStats } from '../hooks/courseWithStats';
import { ratingType } from '../helpers/enums/ratingType';
import { surveyStatus } from '../helpers/enums/surveyStatus';

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const PageBody = styled.div`
  display: block;
  width: 90%;
`;

const PageContent = styled.div`
  position: relative;
  padding-top: 37px;
  padding-left: 70px;
  padding-right: 70px;
  width: 45vw;
  margin: 0 auto;
  margin-top: -120px;
  max-width: 1260px;
`;

const Video = styled.video`
  padding-top: 20px;
  width: 100%;
  outline: none;
`;

const Survey = props => {
  const surveyId = props.match.params.surveyId;
  const courseId = props.match.params.courseId;

  const [surveyScores, setSurveyScores] = useState([]);
  const [surveyStats, setSurveyStats] = useState({});

  useEffect(() => {
    const profileData = profile.get();

    const fecthData = async () => {
      const surveyStatsResponse = await getSurveyStats(profileData.id, surveyId);
      setSurveyStats(surveyStatsResponse.data);
    };

    fecthData();
  }, [surveyId]);

  const onSurveyScoresChange = useCallback(surveyScores => setSurveyScores(surveyScores), []);
  const { loading, error, data } = useCourseWithStats(courseId);

  if (loading) return <Loading />;
  if (error) return <p>error</p>;

  const profileState = profile.get();

  const survey = data.course.surveys.find(survey => survey.id === surveyId);

  return (
    <PageWrapper>
      <PageBody>
        <SurveyHeader
          title={survey.topic}
          onBackClick={() => props.history.push(`/course/${courseId}`)}
        />

        <PageContent className="page-content">
          {survey.status === null && survey.topicStartVideoUrl && (
            <>
              <Video controls>
                <source src={survey.topicStartVideoUrl} />
              </Video>
            </>
          )}
          {survey.status === surveyStatus.EVALUATED && survey.topicFinishVideoUrl && (
            <>
              <Video controls>
                <source src={survey.topicFinishVideoUrl} />
              </Video>
            </>
          )}

          <Questionnaire
            participantId={profileState.id}
            survey={survey}
            courseId={courseId}
            onSurveyScoresChange={onSurveyScoresChange}
          />
        </PageContent>
      </PageBody>

      <ProfileRightBlock
        ratingData={{
          type: ratingType.SURVEY,
          data: {
            surveyStats,
            surveyScores
          }
        }}
        background={'gray'}
      />
    </PageWrapper>
  );
};

export const SurveyPage = withRouter(Survey);
